import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, last } from 'lodash';
import { Link } from 'react-router-dom';
import { wordsWithStyles, renderBoldString } from 'helpers';
import config from '../config';

export default function () {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courses || []);
  const loaded = useSelector((state) => state.coursesLoaded);

  const [keyword, setKeyword] = useState('');
  const [{ searchResult, keywords }, setSearchResult] = useState({ searchResult: [] });
  const _onSearch = (e) => {
    setKeyword(e.target.value);
    const keywords = e.target.value.trim().split(' ');
    let searchResult = courses.filter((course) =>
      keywords.reduce((result, word) => {
        const containedInUniversity = (course.university_name_th || '').indexOf(word) > -1;
        const containedInFaculty = (course.faculty_name_th || '').indexOf(word) > -1;
        const containedInCampus = (course.campus_name_th || '').indexOf(word) > -1;
        const containedInField = (course.field_name_th || '').indexOf(word) > -1;
        const containedInProgram = (course.program_name_th || '').indexOf(word) > -1;
        const containedInMajor = (course.major_name_th || '').indexOf(word) > -1;

        const isContained =
          containedInUniversity ||
          containedInFaculty ||
          containedInCampus ||
          containedInField ||
          containedInProgram ||
          containedInMajor ||
          (course.university_id === '031' && (word || '').indexOf('กสพท') > -1);
        if (!isContained && last(word) === 'ะ') {
          const newWord = word.slice(0, word.length - 1);
          const containedInUniversity = (course.university_name_th || '').indexOf(newWord) > -1;
          const containedInFaculty = (course.faculty_name_th || '').indexOf(newWord) > -1;
          const containedInCampus = (course.campus_name_th || '').indexOf(newWord) > -1;
          const containedInField = (course.field_name_th || '').indexOf(newWord) > -1;
          const containedInProgram = (course.program_name_th || '').indexOf(newWord) > -1;
          const containedInMajor = (course.major_name_th || '').indexOf(newWord) > -1;
          return (
            result && (containedInUniversity || containedInFaculty || containedInCampus || containedInField || containedInProgram || containedInMajor)
          );
        }
        return result && isContained;
      }, true),
    );

    setSearchResult({
      keywords,
      searchResult: searchResult.length === courses.length ? [] : searchResult,
    });
  };

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const res = await fetch(`${config.apiBaseUrl}/courses.json?ts=${new Date().getTime().toString(16)}`);
        const json = await res.json();
        dispatch({ type: 'UPDATE_COURSES', data: json });
      } catch (error) {
        console.error('Error while trying to fetch universities.', error);
      }
    };
    if (!loaded) {
      fetchCourses();
    }
  }, [dispatch, loaded]);

  return (
    <>
      <div className="tt-sec">
        <div className="wrap s-container">
          <div className="head">
            <a href={config.wwwBaseUrl}>
              <img src="/img/logo.svg" alt="TCAS Logo" width="160" height="60" />
            </a>
            <h1>ข้อมูลหลักสูตร</h1>
            <div className="sub">ค้นหาข้อมูลการรับสมัครของสถาบันต่างๆ ปีการศึกษา 2568</div>
          </div>
          <div className="banner _desktop">
            <img src="/img/courses.webp" alt="ข้อมูลหลักสูตร" width="400" height="400" />
          </div>
        </div>
      </div>
      <div className="t-wrap">
        <div className="input-search">
          <input id="search" type="text" placeholder="พิมพ์ชื่อมหาวิทยาลัย คณะ หรือหลักสูตร" value={keyword} onChange={_onSearch} />
          <i className="i-search">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.25 3C6.25482 3 3 6.25482 3 10.25C3 14.2452 6.25482 17.5 10.25 17.5C11.9782 17.5 13.5669 16.8895 14.8145 15.875L19.7197 20.7803C19.7888 20.8522 19.8716 20.9097 19.9632 20.9493C20.0548 20.9889 20.1534 21.0098 20.2532 21.0108C20.3529 21.0118 20.4519 20.9929 20.5443 20.9552C20.6367 20.9175 20.7206 20.8617 20.7912 20.7912C20.8617 20.7206 20.9175 20.6367 20.9552 20.5443C20.9929 20.4519 21.0118 20.3529 21.0108 20.2532C21.0098 20.1534 20.9889 20.0548 20.9493 19.9632C20.9097 19.8716 20.8523 19.7888 20.7803 19.7197L15.875 14.8145C16.8895 13.5669 17.5 11.9782 17.5 10.25C17.5 6.25482 14.2452 3 10.25 3ZM10.25 4.5C13.4345 4.5 16 7.06548 16 10.25C16 11.8013 15.3881 13.2029 14.3955 14.2353C14.3343 14.2803 14.2803 14.3343 14.2354 14.3955C13.2029 15.3881 11.8013 16 10.25 16C7.06548 16 4.5 13.4345 4.5 10.25C4.5 7.06548 7.06548 4.5 10.25 4.5Z"
                fill="#1A477F"
              ></path>
            </svg>
          </i>
        </div>
        <Link to="/universities/" className="s-flex search-hide">
          <span className="i-university _svg">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48">
              <path
                d="M 24 4 C 23.731 4 23.462156 4.0717969 23.222656 4.2167969 L 9.6132812 12.466797 C 7.2492813 13.898797 6 15.984 6 18.5 C 6 19.329 6.672 20 7.5 20 L 40.5 20 C 41.328 20 42 19.329 42 18.5 C 42 15.984 40.750719 13.898797 38.386719 12.466797 L 24.777344 4.2167969 C 24.537844 4.0717969 24.269 4 24 4 z M 24 11 C 25.105 11 26 11.895 26 13 C 26 14.105 25.105 15 24 15 C 22.895 15 22 14.105 22 13 C 22 11.895 22.895 11 24 11 z M 11.476562 21.978516 A 1.50015 1.50015 0 0 0 10 23.5 L 10 36.5 A 1.50015 1.50015 0 0 0 11.5 38 L 36.5 38 A 1.50015 1.50015 0 0 0 38 36.5 L 38 23.5 A 1.50015 1.50015 0 1 0 35 23.5 L 35 35 L 33 35 L 33 23.5 A 1.50015 1.50015 0 1 0 30 23.5 L 30 35 L 28 35 L 28 23.5 A 1.50015 1.50015 0 1 0 25 23.5 L 25 35 L 23 35 L 23 23.5 A 1.50015 1.50015 0 1 0 20 23.5 L 20 35 L 18 35 L 18 23.5 A 1.50015 1.50015 0 1 0 15 23.5 L 15 35 L 13 35 L 13 23.5 A 1.50015 1.50015 0 0 0 11.476562 21.978516 z M 7.5 40 A 1.50015 1.50015 0 1 0 7.5 43 L 40.5 43 A 1.50015 1.50015 0 1 0 40.5 40 L 7.5 40 z"
                fill="currentColor"
              ></path>
            </svg>
          </span>
          <span>รายชื่อสถาบัน →</span>
        </Link>
        <div id="results" className={`t-result -left ${get(searchResult, 0) && 'active'}`}>
          {get(searchResult, 0) && (
            <>
              <h2>
                <strong>ผลการค้นหา</strong>
                <b>{searchResult.length && `${searchResult.length} หลักสูตร`}</b>
              </h2>
              <ul className="t-programs">
                {searchResult.slice(0, 1000).map((row, index) => {
                  const programNameString = `${row.program_name_th}${row.major_name_th ? ` สาขาวิชา${row.major_name_th}` : ''}`;
                  const logo = 'https://assets.mytcas.com/i/logo/' + row.university_id + '.png';
                  return searchResult.length ? (
                    <li key={index}>
                      <Link to={`/programs/${row.program_id}?${row.major_id ? `major=${row.major_id}` : ''}`}>
                        <img src={logo} alt="Logo" width="50" height="50" />
                        <h3>
                          {wordsWithStyles(keywords, programNameString).map(renderBoldString)}
                          <small> {(programNameString || '').indexOf(row.program_type_name_th) === -1 ? `(${row.program_type_name_th})` : ''}</small>
                        </h3>
                        <b>
                          {wordsWithStyles(keywords, row.faculty_name_th).map(renderBoldString)}
                          <span className="t-sep">›</span>
                          {wordsWithStyles(keywords, row.field_name_th).map(renderBoldString)}
                        </b>
                        <span>
                          {wordsWithStyles(
                            keywords,
                            `${row.university_name_th}${row.campus_name_en === 'Main Campus' ? '' : ` ${row.campus_name_th}`}`,
                          ).map(renderBoldString)}
                        </span>
                      </Link>
                    </li>
                  ) : (
                    <li>ไม่พบข้อมูล</li>
                  );
                })}
              </ul>
            </>
          )}
        </div>
      </div>
    </>
  );
}
