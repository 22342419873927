import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Containers
import Header from 'containers/Header';
import Footer from 'containers/Footer';
import Home from 'containers/Home';
import Fields from 'containers/Fields';
import Faculties from 'containers/Faculties';
import Programs from 'containers/Programs';
import ProgramDetail from 'containers/ProgramDetail';
import Universities from './Universities';
// import Venue from './Venue.js.bak';
// import VenueDetail from './VenueDetail.js.bak';

export default function () {
  return (
    <>
      <Header />
      <main className="site-body">
        <Route exact path="/" component={Home} />
        <Route exact path="/universities" component={Universities} />
        <Route exact path="/universities/:universityId" component={Faculties} />
        <Route exact path="/universities/:universityId/faculties/:facultyId" component={Fields} />
        <Route exact path="/universities/:universityId/faculties/:facultyId/fields/:fieldId" component={Programs} />

        <Route exact path="/program/:programId" render={(props) => <Redirect to={`/programs/${props.match.params.programId}`}></Redirect>}></Route>

        <Route exact path="/programs/:programId" component={ProgramDetail} />
        {/* <Route exact path="/venues" component={Venue} />
          <Route exact path="/venues/:id" component={VenueDetail} /> */}
      </main>
      <Footer />
    </>
  );
}
